@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/* https://tailwindcss.com/docs/extracting-components */
@layer components {
  .navlink {
    @apply inline-flex items-center text-sm font-medium focus:outline-none;
  }
  .navlink--state-active {
    @apply text-gray-900;
  }
  .navlink--state-inactive {
    @apply text-gray-500 hover:text-gray-700 focus:text-gray-700;
  }
  .menu__link {
    @apply text-left pl-3 pr-4 py-2 border-l-4 text-base font-medium focus:outline-none;
  }
  .menu__link--state-active {
    @apply border-indigo-500 text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700;
  }
  .menu__link--state-inactive {
    @apply border-transparent text-gray-600 focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300;
  }
  .input {
    @apply placeholder-gray-400 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md;
  }
  .disabled {
    @apply opacity-50 pointer-events-none;
  }
  .input--state-error {
    @apply border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500;
  }
  .btn {
    @apply inline-flex items-center justify-center border border-transparent rounded-md shadow-sm font-medium;
  }
  .btn--primary {
    @apply text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
  .btn--secondary {
    @apply text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
  .btn--white {
    @apply border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
  .btn--xs {
    @apply px-2.5 py-1.5 text-xs rounded;
  }
  .btn--sm {
    @apply px-3 py-2 text-sm leading-4;
  }
  .btn--md {
    @apply px-4 py-2 text-sm;
  }
  .btn--lg {
    @apply px-4 py-2 text-base;
  }
  .btn--xl {
    @apply px-6 py-3 text-base;
  }
  .badge {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
  }
  .badge--gray {
    @apply bg-gray-300 text-gray-800;
  }
  .badge--indigo {
    @apply bg-indigo-200 text-indigo-800;
  }
}
/* https://tailwindcss.com/docs/adding-new-utilities */
@layer utilities {
}
